import { NEUX_URL } from './constants';

export const formatImageSource = imageSource => {
  // console.log("localhost:3000/" + imageSource);
  // console.log(require(imageSource))
  // if (process.env.NODE_ENV === 'development') return "localhost:3000/" + imageSource;
  // return imageSource.replace('assets/tiles/', 'images/');
  // import image from imageSource;
  return window?.location?.origin + '/' + imageSource;
};

export const formatUrlWithNeux = url => {
  return new URL('https://mocks.neux.io/' + url);
};

export const formatTimingUrlWithNeux = url => {
  if (!url) return '';
  return new URL(`${NEUX_URL}/sc/timings/` + url + '/raw');
};

export const formatMapUrlWithNeux = url => {
  if (!url) return '';
  // return new URL("https://mocks.neux.io/" + url);
  return new URL(`${NEUX_URL}/sc/map/` + url + '/raw');
};

export const formatQuestionUrlWithNeux = url => {
  if (!url) return '';
  return new URL(`${NEUX_URL}/sc/questions/` + url + '/raw');
};
export const formatTimeWithSecond = time => {
  const miliSecond = Number((time - Math.floor(time)) / 0.1).toFixed(0);
  const secondTime = Math.floor(time);
  const minutes = Math.floor(secondTime / 60);
  const seconds = secondTime - minutes * 60;
  function padLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }
  const finalTime =
    padLeft(minutes, '0', 2) +
    ':' +
    padLeft(seconds, '0', 2) +
    ':' +
    miliSecond;
  return finalTime;
};

export const posToString = pos => {
  return pos.replaceAll(':', '_');
};

export const getSequenceImagePath = (guid, type) => {
  const hostName = 'https://io.musicascode.com';
  const first2Letter = guid.slice(0, 2);
  const second2Letter = guid.slice(2, 4);
  const imageFolder = 'static';
  return `${hostName}/${imageFolder}/${first2Letter}/${second2Letter}/${guid}-${type}.png`;
};

export const trimGuid = (text, pad = 8) => {
  if (text.length <= pad * 2) return text;
  return `${text.slice(0, pad)}...${text.slice(
    text.length - pad,
    text.length,
  )}`;
};
