import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useAxios } from 'utils/hooks';
import { NEUX_URL, TAB_NAME } from 'utils/constants';
import { toast } from 'react-toastify';
import { Select, MenuItem } from '@material-ui/core';
import Game, { DRAGGABLE_TYPE } from '../components/DraggableGame';
import './UpdateSequenceDesign.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DEFAULT_SEQUENCE_DATA = {
  description: '',
  icon: '',
  icon_color: '',
  hints_visible: 'true',
  image_icon: '',
  image_header: '',
  bpm: 124.4,
  lifecycle: 'wip',
};

export default ({ id, setTabName }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sequenceName, setSequenceName] = useState('');
  const [sequenceData, setSequenceData] = useState({});
  const [listGames, setListGames] = useState([]);
  const [listSequenceGames, setListSequenceGames] = useState([]);
  const [startMapsList, setStartMapList] = useState([]);
  const [endMapsList, setEndMapList] = useState([]);
  const [startMap, setStartMap] = useState('');
  const [endMap, setEndMap] = useState('');
  const axiosInstance = useAxios(NEUX_URL);

  const onChangeName = event => {
    setSequenceName(event.target.value);
  };

  const onStartMapChamge = event => {
    setStartMap(event.target.value);
  };

  const onEndMapChamge = event => {
    setEndMap(event.target.value);
  };

  const onSaveSequence = async () => {
    try {
      setLoading(true);
      const method = id ? 'post' : 'put';
      const body = {
        ...sequenceData,
        name: sequenceName,
        games: listSequenceGames.map((game, index) => ({
          order: index,
          game_guid: game?.guid,
        })),
      };

      if (startMap) {
        body.welcome_map_guid = startMap;
      }

      if (endMap) {
        body.finish_map_guid = endMap;
      }

      const { data } = await axiosInstance[method]('/sc/sequence', body);
      toast.success('Saved');
      if (!id) {
        history.push(`/sequence/${data?.guid}?tab=description`);
        setTabName(TAB_NAME.DESCRIPTION);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let games = listSequenceGames;

      if (id) {
        const resp = await axiosInstance.get(`/sc/sequence/${id}`);

        if (resp?.data) {
          setSequenceData(resp.data);
          setSequenceName(resp.data.name);
          setListSequenceGames(resp.data.games);
          setStartMap(resp.data.welcome_map_guid);
          setEndMap(resp.data.finish_map_guid);
          games = resp.data.games;
        }
      } else {
        setSequenceData(DEFAULT_SEQUENCE_DATA);
      }

      const resp = await axiosInstance.get('/sc/game/search/lifecycle/published/newest');

      if (resp?.data?.game) {
        //const allGames = resp.data.game.filter(
        //  ({ guid }) => !games.find(item => item.guid === guid),
        //);
        const allGames = resp.data.game;        

        setListGames(allGames);
      }

      const respStartMap = await axiosInstance.get(
        `/sc/map/search/welcome/newest`,
      );
      if (respStartMap?.data?.map) {
        setStartMapList(respStartMap?.data?.map);
      }

      const respEndMap = await axiosInstance.get(`/sc/map/search/finish/newest`);
      if (respEndMap?.data?.map) {
        setEndMapList(respEndMap?.data?.map);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = result => {
    const index = result?.destination?.index;
    const guid = result?.draggableId.split('__')[0];
    if (result?.destination?.droppableId === 'sequence') {
      const game = listGames.find(item => item.guid === guid);
      if (result?.source?.droppableId !== result?.destination?.droppableId) {
        setListSequenceGames([
          ...listSequenceGames.slice(0, index),
          game,
          ...listSequenceGames.slice(index, listSequenceGames.length),
        ]);
      } else {
        setListSequenceGames(
          reorder(
            listSequenceGames,
            result?.source?.index,
            result?.destination?.index,
          ),
        );
      }
    } else if (
      result?.source?.droppableId !== result?.destination?.droppableId
    ) {
      setListSequenceGames(
        listSequenceGames.filter(item => item.guid !== guid),
      );
    }
  };

  useEffect(() => {
    if (axiosInstance) {
      fetchData();
    }
  }, [axiosInstance]);

  return (
    <div className="update-sequence-design-container">
      <div className="row sequence-form-group">
        <div className="col-md-8 col-sm-12">
          <input
            type="text"
            placeholder="Sequence Name"
            aria-label="Sequence Name"
            aria-describedby="basic-addon1"
            onChange={onChangeName}
            value={sequenceName}
            className="sequence-name-input"
            disabled={loading}
          />
        </div>
        <div className="col-md-2 col-sm-6 sequence-button-container">
          <button
            className="btn btn-primary save-sequence-button"
            type="button"
            onClick={onSaveSequence}
            disabled={!sequenceName || loading}
          >
            Save
          </button>
        </div>
        <div className="col-md-2 col-sm-6 sequence-button-container">
          <Link to="/sequences">
            <button
              className="btn btn-primary close-sequence-button"
              type="button"
            >
              Close
            </button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 sequence-form-group">
          <p>Select Sequence Welcome Map: </p>
          <Select
            id="map"
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={onStartMapChamge}
            value={startMap}
            disabled={loading}
            className="game-select-item"
          >
            <MenuItem value="" disabled>
              None
            </MenuItem>
            {startMapsList.map(map => (
              <MenuItem value={map.guid} key={map.guid}>
                {map.name}
              </MenuItem>
            ))}
          </Select>
          <br />
        </div>
        <div className="col-md-6 sequence-form-group">
          <p>Select Sequence Finish Map: </p>
          <Select
            id="map"
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={onEndMapChamge}
            value={endMap}
            disabled={loading}
            className="game-select-item"
          >
            <MenuItem value="" disabled>
              None
            </MenuItem>
            {endMapsList.map(map => (
              <MenuItem value={map.guid} key={map.guid}>
                {map.name}
              </MenuItem>
            ))}
          </Select>
          <br />
        </div>
      </div>
      <div className="row sequence-content-group">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="col-lg-6 sequence-content-column">
            <p>List Game</p>
            <Droppable droppableId="game">
              {(provided, snapshot) => (
                <div
                  // eslint-disable-next-line
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNames('droppable-container', {
                    dragging: snapshot.isDraggingOver,
                  })}
                >
                  {listGames.map((game, index) => (
                    <Game
                      game={game}
                      index={index}
                      // eslint-disable-next-line
                      key={`${game.guid}__${DRAGGABLE_TYPE.DRAG}__${index}`}
                      type={DRAGGABLE_TYPE.DRAG}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-lg-6 sequence-content-column">
            <p>Sequence Game</p>
            <Droppable droppableId="sequence">
              {(provided, snapshot) => (
                <div
                  // eslint-disable-next-line
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNames('droppable-container', {
                    dragging: snapshot.isDraggingOver,
                  })}
                >
                  {listSequenceGames.map((game, index) => (
                    <Game
                      game={game}
                      index={index}
                      // eslint-disable-next-line
                      key={`${game.guid}__${DRAGGABLE_TYPE.DROP}__${index}`}
                      type={DRAGGABLE_TYPE.DROP}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};
