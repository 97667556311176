import React, { useCallback, useEffect, useState } from 'react';
import './PageHeader.scss';
import queryString from 'query-string';
import cl from 'classnames';
import { Select, MenuItem } from '@material-ui/core';
import * as giIcon from 'react-icons/gi';
import { MdDashboard, MdEdit } from 'react-icons/md';
import { FaSolarPanel } from 'react-icons/fa';
import { SEQUENCE_LIFECYCLE } from 'utils/constants';

export const ButtonType = {
  DASH: 'dash',
  EDITOR: 'editor',
  PANEL: 'panel',
};
const ButtonList = [
  {
    id: ButtonType.DASH,
    name: 'DASHBOARD',
    icon: <MdDashboard size={20} />,
  },
  {
    id: ButtonType.EDITOR,
    name: 'EDITOR',
    icon: <MdEdit size={20} />,
  },
  {
    id: ButtonType.PANEL,
    name: 'PANEL',
    icon: <FaSolarPanel size={20} />,
  },
];
const PageHeader = ({
  guid,
  onSave,
  onCancel,
  icon,
  iconColor,
  defaultName,
  defaultTab,
  defaultLifecycle,
  showLifecycle,
  isLoading,
  onChangeLifecycle,
  isDisableSave,
  ...props
}) => {
  const [name, setName] = useState('');

  const [lifecycle, setLifecycle] = React.useState('');
  const [buttonAction, setButtonAction] = React.useState(defaultTab);

  const IconComponent = icon ? giIcon[icon] : giIcon.GiBasketballBall;
  const { dash, editor } = props;

  const onChangeName = event => {
    setName(event.target.value);
  };

  const handleChangeLifeCycle = e => {
    const { value } = e.target;
    setLifecycle(value);
    if (onChangeLifecycle) {
      onChangeLifecycle(value);
    }
  };

  const onButtonClick = value => {
    setButtonAction(value);
    const newUrl = queryString.stringifyUrl({
      url: window.location.pathname,
      query: {
        tab: value,
      },
    });
    window.history.replaceState(null, '', newUrl);
  };

  const onSaving = useCallback(() => {
    if (typeof onSave === 'function') {
      onSave({ name, lifecycle });
    }
  }, [name, lifecycle, onSave]);

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    setLifecycle(defaultLifecycle);
  }, [defaultLifecycle]);

  return (
    <div className="page-header">
      <div className="page-header__information">
        <div className="page-header__top"></div>
        <div className="page-header__bottom">
          <div
            className="page-header__bottom__icon"
            style={iconColor ? { color: iconColor } : {}}
          >
            <IconComponent />
          </div>
          <div className="page-header__bottom__info">
            <div className="page-header__bottom__info__basic">
              <div className="page-header__bottom__info__basic__guid">
                {guid}
              </div>
              <div className="page-header__bottom__info__basic__name">
                <input
                  type="text"
                  className="form-control name-input"
                  placeholder="Name"
                  aria-label="Name"
                  aria-describedby="basic-addon1"
                  onChange={onChangeName}
                  value={name}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="page-header__bottom__info__right">
              {showLifecycle && (
                <div className="page-header__bottom__info__right__lifecycle">
                  <Select
                    fullWidth
                    onChange={handleChangeLifeCycle}
                    value={lifecycle}
                    displayEmpty
                    disabled={isLoading}
                  >
                    {SEQUENCE_LIFECYCLE.map(item => (
                      <MenuItem value={item} key={item}>
                        {item.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <div className="page-header__bottom__info__buttons">
                <button
                  className="btn btn-primary page-header__bottom__info__action__save"
                  onClick={onSaving}
                  disabled={isLoading || isDisableSave || !name || !lifecycle}
                >
                  Save
                </button>
                {typeof onCancel === 'function' && (
                  <button
                    className="btn btn-primary page-header__bottom__info__action__cancel"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-header__actions">
        <div className="page-header__actions__buttons">
          {ButtonList.filter(item => Boolean(props[item.id])).map(item => (
            <button
              key={item?.id}
              className={cl(
                'btn page-header__actions__buttons__item',
                { 'btn-info': item?.id === buttonAction },
                { 'btn-light': item?.id !== buttonAction },
              )}
              onClick={() => onButtonClick(item?.id)}
            >
              <span>{item?.icon}</span> <span>{item?.name}</span>
            </button>
          ))}
        </div>
      </div>
      <div
        className={cl('page-header__tabs', {
          show: Boolean(props[buttonAction]),
        })}
      >
        {buttonAction === ButtonType.DASH && dash}
        {buttonAction === ButtonType.EDITOR && editor}
      </div>
    </div>
  );
};

export default PageHeader;

// uuid
// save button
// cancel button
// life cycle dropdown
// icon - maybe
// name of guid
